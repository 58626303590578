import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import { LazyLoadImage } from 'react-lazy-load-image-component';


var filters = {}

function Collection() {
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [allData, setAllData] = useState([]);
	const [data, setData] = useState([]);
	const [attributes, setAttributes] = useState([]);
	const [attributeNames, setAttributeNames] = useState([]);
	const [buttonNames, setButtonNames] = useState([]);

	useEffect(() => {
		fetch("https://nft.shoesfuse.com/alldata")
			.then(res => res.json())
		  	.then(result => {				
				setIsLoaded(true);
				setAllData(result);
				setData(result.data);
				setAttributes(result.attributes);
				setAttributeNames(Object.keys(result.attributes));
				setButtonNames(Object.keys(result.attributes))
			}, error => {
				setIsLoaded(false);
				setError(error);
			}
		)
	}, []);

	const filter = (i, key, value) => {
		filters[key] = value

		if (value == null) {
			delete filters[key]
		}

		var tempData = new Set();

		for (var j in allData.data) {
			const item = allData.data[j]

			if (item["attributes"]) {
				var allPass = true;
				for (var k in filters) {
					if (!item["attributes"][k] || item["attributes"][k] != filters[k]) {
						allPass = false;
						break;
					}
				}

				if (allPass) {
					tempData.add(item);
				}
			}
		}

		tempData = Array.from(tempData).sort();


		var tempButtonNames = [...buttonNames];
		tempButtonNames[i] = value == null ? key : value

		document.dispatchEvent(new MouseEvent('click'));
		setData(Object.keys(filters).length === 0 ? allData.data : tempData);
		setButtonNames(tempButtonNames);

	}

	if (error) {
		return <div className="vh-100 vw-100 flex centerEverything text-center bg-tan fw-800 rubikFont hex-1e ft-100">Error: {error.message}</div>;
	} else if (!isLoaded) {
		return <div className="vh-100 vw-100 flex centerEverything text-center bg-tan fw-800 rubikFont hex-1e ft-100">Loading...</div>;
  	} else {
	
		const values = (i, key, values) => values.map(value => 
			<li><button className="dropdown-item" href="#" onClick={() => filter(i, key, value)}>{value}</button></li>
		)

		const buttons = buttonNames.map((buttonName, i) =>
			<div className="dropdown">
				<button type="button" className={"dropdown-toggle btn w-150p " + (buttonName != attributeNames[i] ? "bg-red" : "")} id={"dropdown" + (i)} data-bs-toggle="dropdown" aria-expanded="false">
					{buttonName}
				</button>
				<ul className="dropdown-menu" aria-labelledby={"dropdown" + (i)} style={{height: '300', overflowY: 'auto'}}>
					<li><button className="dropdown-item" href="#" onClick={() => filter(i, attributeNames[i], null)}>Clear Filter</button></li>
					<div className="dropdown-divider"></div>
					{ values(i, attributeNames[i], attributes[attributeNames[i]]) }
				</ul>
			</div>
		)

		const images = data.map((item) =>
			<div className="m-1 mb-3">
				<div className="img">
					<LazyLoadImage height="100%" width="100%" src={item.image.replace("ipfs://", "https://ipfs.io/ipfs/").replace("/image.png", "/image_720.png")} />
				</div>

				<p className="small fw-bold rubikFont text-uppercase">{item.name}</p>
			</div>
		);

		return (<>
			<div className="fixed-top">
				<Header />
				<div className="d-flex flex-wrap justify-content-center w-100 bg-tan px-2" id="buttons">
				{buttons}
			</div>
			</div>
			<div style={{height: 150}}></div>


			<div className="d-flex flex-wrap justify-content-around mt-2" style={{backgroundColor: '#fefefe'}}>
				{images}
			</div>
		</>);
	}
}

export default Collection;
