import React, { Component } from "react";
import Home from "./Home";
import Collection from "./Collection";
import NotFoundPage from "./NotFoundPage";

import store from "./redux/store";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";


function App() {
    return (
        <Routes>
            <Route path="/" element={ 
                <Provider store={store}>
                    <Home /> 
                </Provider>
            } />
            <Route path="/collection" element={ 
                <Collection />
            } />
            <Route path="*" element={ <NotFoundPage /> } />

        </Routes>
    );
}

export default App;
