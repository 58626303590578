import React, { useEffect, useState, useRef } from "react";

const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;

function Header() {
    const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		SYMBOL: "",
		GAS_LIMIT: 0,
		MARKETPLACE_LINK: "",
	});

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: { "Content-Type": "application/json", Accept: "application/json" },
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => { getConfig(); }, []);

	return (<>
	<div className="bg-tan vw-100 py-4 px-2">
		<div>
			<div className="col col-12 d-block d-md-none">
				<a className="anone" href="/">
					<div className="rubikFont text-center">
						<div className="hex-red fw-800" style={{fontSize: '20px', lineHeight: '90%', letterSpacing: '-2px'}}>SHOES</div>
						<div className="hex-gray fw-800" style={{fontSize: '20px', lineHeight: '90%', letterSpacing: '-2px'}}>FUSE</div>
					</div>
				</a>
			</div>
		</div>
		<div className="my-0 px-4 d-none d-md-block">
			<div className="row centerEverything">
				<div className="col col-4 text-start rubikFont text-uppercase">
					<h6 className="mb-0 fw-800">ETHEREUM</h6>
					<p className="small hex-gray fw-500"><a className="a" target={"_blank"} href={CONFIG.SCAN_LINK}>{truncate(CONFIG.CONTRACT_ADDRESS, 11)}</a></p>

				</div>
				<div className="col col-4">
					<a className="anone" href="/">
						<div className="rubikFont text-center">
							<div className="hex-red fw-800" style={{fontSize: '30px', lineHeight: '90%', letterSpacing: '-2px'}}>SHOES</div>
							<div className="hex-gray fw-800" style={{fontSize: '30px', lineHeight: '90%', letterSpacing: '-2px'}}>FUSE</div>
						</div>
					</a>
				</div>
				<div className="col col-4 text-end">
					<a className="anone" href="https://www.twitter.com/shoesfuse" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
							<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
						</svg>
					</a>
				</div>
			</div>
		</div>
    </div>
	</>);
}

export default Header;
