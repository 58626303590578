import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import { fetchData } from "../data/dataActions";


export const connect = () => {
  return async (dispatch) => {
    dispatch({ type: "CONN_REQUEST" });

    const abiResponse = await fetch("/config/abi.json", { headers: { "Content-Type": "application/json",  Accept: "application/json" }});
    const abi = await abiResponse.json();

    const configResponse = await fetch("/config/config.json", { headers: { "Content-Type": "application/json", Accept: "application/json" }});
    const CONFIG = await configResponse.json();

    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);

      try {
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        const mainAccount = accounts[0];

        const networkId = await ethereum.request({ method: "net_version" });

        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(abi, CONFIG.CONTRACT_ADDRESS);

          dispatch({ type: "CONN_SUCCESS", payload: {
              account: mainAccount,
              smartContract: SmartContractObj,
              web3: web3,
            }
          });

          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });

          ethereum.on("chainChanged", () => { window.location.reload(); });
          
        } else {
          dispatch({ type: "CONN_FAIL", payload: `Change network to ${CONFIG.NETWORK.NAME}.` });
        }
      } catch (err) {
        console.log(err);
        dispatch({ type: "CONN_FAIL", payload: "Something went wrong." });
      }
    } else {
      dispatch({ type: "CONN_FAIL", payload: "Install Metamask." });
      alert("Install Metamask");
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ACCOUNT",
      payload: { account: account },
    });
    dispatch(fetchData(account));
  };
};
