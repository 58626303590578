import store from "../store";

export const fetchData = () => {
  return async (dispatch) => {
    dispatch({ type: "SMART_CONTRACT_DATA_REQUEST_PENDING" });
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let reservedAmount = await store
        .getState()
        .blockchain.smartContract.methods.reserved()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
      let maxTokens = await store
        .getState()
        .blockchain.smartContract.methods.maxTokens()
        .call();
      let mintActive = await store
        .getState()
        .blockchain.smartContract.methods.mintActive()
        .call();

      console.log(totalSupply, reservedAmount, cost, maxTokens, mintActive)

      dispatch({
        type: "SMART_CONTRACT_DATA_REQUEST_SUCCESS",
        payload: {
          totalSupply,
          reservedAmount,
          cost,
          maxTokens,
          mintActive,
        }
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SMART_CONTRACT_DATA_REQUEST_FAIL",
        payload: "Could not load data from contract.",
      });
    }
  };
};
