import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import Header from "./Header";

const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;

function Home() {
	const ActiveMintStatus = {
		PASSIVE: "0",
		MINTING: "1",
		SUCCESS: "2",
		ERROR: "3"
	};
	
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [activeMintStatus, setActiveMintStatus] = useState(ActiveMintStatus.PASSIVE);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		SYMBOL: "",
		GAS_LIMIT: 0,
		MARKETPLACE_LINK: "",
	});

	const claimNFTs = () => {
		let costInWei = data.costInWei;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(costInWei * mintAmount);
		let totalGasLimit = String(gasLimit * mintAmount);
		// console.log(totalCostWei, totalGasLimit);
		
		setActiveMintStatus(ActiveMintStatus.MINTING);

		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once("error", (err) => {
				console.log(err);
				setActiveMintStatus(ActiveMintStatus.ERROR);
			})
			.then((receipt) => {
				console.log(receipt);
				setActiveMintStatus(ActiveMintStatus.SUCCESS);
				dispatch(fetchData(blockchain.account));
			});
	};

	const changeMintAmount = (editAmountBy) => {
		let newMintAmount = mintAmount + editAmountBy;
		if (newMintAmount < 1) newMintAmount = 1;
		else if (newMintAmount > 10) newMintAmount = 10;
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: { "Content-Type": "application/json", Accept: "application/json" },
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => { getConfig(); }, []);
	useEffect(() => { getData(); }, [blockchain.account]);

	useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = "js/script_i.js";
        scriptTag.async = true;
		document.body.append(scriptTag);
        return () => scriptTag.remove();
    }, []);


	const titleText = (text) =>
		<h1 className="ft-100 fw-800 text-uppercase">{text}</h1>
	
	const subText = (text) => 
		<p className="hex-8 rightAlign" style={{maxWidth: 388}}>{text}</p>

	const button = (bgColor, color, text) =>
		<div className="custombtn my-4 py-3 fw-800 text-uppercase rightAlign" style={{backgroundColor: bgColor, color: color}}>{text}</div>

	const mintFlow = () => {
		if (Number(data.totalSupply) + Number(data.reservedAmount) >= Number(data.maxTokens) && activeMintStatus == ActiveMintStatus.PASSIVE) {
			return <>
				{ titleText(<>Shoes <br/>Fuse NFT</>) }
				<a className="a" target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
					{ button('#2081E2', 'fff', <>On Opensea</>) }
				</a>
				{ subText(<>Mint has ended. Trade ${CONFIG.SYMBOL} NFTs on Opensea.</>) }
			</>

		} else if (blockchain.account === "" || blockchain.smartContract === null) {
			return <>
				{ titleText(<>Shoes <br/>Fuse NFT</>) }
				<div className="custombtn my-4 py-3 fw-800 text-uppercase blink rightAlign" style={{backgroundColor: '#DE443D', color: '#fff', outline: '1px solid #DE443D'}}
					onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }}
				><span style={{opacity: 100}}>Add to Cart</span></div>
				{ subText(<>Connect to the <span className="fw-800">{CONFIG.NETWORK.NAME}</span> network.</>) }

				{ blockchain.errorMsg !== "" ? (<p style={{color: "red"}}>{blockchain.errorMsg}</p>) : null }
			</>

		} else if (data.mintActive) {

			if (activeMintStatus == ActiveMintStatus.PASSIVE || activeMintStatus == ActiveMintStatus.ERROR) {
				return <>
					{ titleText(<>{Number(data.totalSupply) + Number(data.reservedAmount)} / {data.maxTokens}</>) }
					<>
						<h3 className="fw-800 hex-gray">MINTED AT {data.cost} {CONFIG.NETWORK.SYMBOL}</h3>
						<div className="my-4 rightAlign round" style={{height: 5, maxWidth: 388, backgroundColor: '#555'}}></div>
						<div>
							<button className="btn btn-lg btn-dark d-inline-block" onClick={(e) => { e.preventDefault(); changeMintAmount(-1); }}>-</button>
							<div className="d-inline-block" style={{width: 20}}></div>
							<button className="btn btn-lg btn-light d-inline-block disabled" style={{opacity: 100}}>{mintAmount}</button>
							<div className="d-inline-block" style={{width: 20}}></div>
							<button className="btn btn-lg btn-dark d-inline-block blink" onClick={(e) => { e.preventDefault(); changeMintAmount(1); }}>+</button>
						</div>
						<div className="custombtn my-4 py-3 fw-800 text-uppercase rightAlign" style={{backgroundColor: "#ADFF92"}}
							onClick={(e) => { e.preventDefault(); claimNFTs(); getData(); } }>MINT</div>
					</>

					{ subText(
						activeMintStatus == ActiveMintStatus.ERROR ?
						<>Something went wrong. Please try again.</> :
						<>Click "+" to mint more than 1 NFT. Click 'Mint' to mint your NFT on the blockchain.</>
					)}
				</>
			} else if (activeMintStatus == ActiveMintStatus.MINTING) {
				return <>
					{ button('#ADFF92', '#444', <>MINTING {mintAmount} NFT(s)...</>)}
					{ subText(<>Confirm transaction on Metamask to mint your NFT(s). Please wait until transaction is approved. Check Metamask for transaction status.</>)}					
				</>
		
			} else if (activeMintStatus == ActiveMintStatus.SUCCESS) {
				return <>
					{ titleText(<>{Number(data.totalSupply) + Number(data.reservedAmount)} / {data.maxTokens}</>) }
					<a className="a" target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
						{ button('#1e1e1e', '', <>
							<div className="d-flex centerEverything rubikFont flex-row-reverse">
								<div className="hoverText">S</div>
								<div className="hoverText">T</div>
								<div className="hoverText">A</div>
								<div className="hoverText">R</div>
								<div className="hoverText">G</div>
								<div className="hoverText">N</div>
								<div className="hoverText">O</div>
								<div className="hoverText">C</div>
							</div>
						</>) }
					</a>
					{ subText(<>🎉 You just minted your very own Shoes Fuse NFT(s)! View in your wallet and on Opensea! 🥳</>) }
					{ party() }
				</>
			} else {
				<></>
			}
		} else {
			return <>
				{ titleText(<>Shoes <br/>Fuse NFT</>) }
				{ button('#1e1e1e', '#EDEDE3', <>Mint Inactive</>) }
				{ subText(<>Please try again later.</>) }
			</>
		}
	}

	return (<>

<div className="vw-100 p-4 centerEverything bg-1e" style={{position: 'fixed', zIndex: -5, display: 'flex', minHeight: '100vh'}}>
	<div className="rubikFont text-center">
		<div className="hex-red fw-800" style={{fontSize: '21vw', lineHeight: '73%', letterSpacing: 'calc(-1.5vw)'}}>SHOES</div>
		<div className="hex-tan fw-800" style={{fontSize: '27vw', lineHeight: '73%', letterSpacing: 'calc(-1.2vw)'}}>FUSE</div>
		<div className="fw-800" style={{fontSize: '3.7vw', lineHeight: '100%', letterSpacing: 'calc(0.05vw)', color: '#535353'}}>LABS
			<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="4vw" viewBox="0 0 24 24" width="6vw" fill="#535353"><rect fill="none" height={24} width={24} /><path d="M20.54,17.73L15,11V5h1c0.55,0,1-0.45,1-1s-0.45-1-1-1H8C7.45,3,7,3.45,7,4s0.45,1,1,1h1v6l-5.54,6.73 C3.14,18.12,3,18.56,3,19c0.01,1.03,0.82,2,2,2H19c1.19,0,2-0.97,2-2C21,18.56,20.86,18.12,20.54,17.73z" /></svg>            
			ETHEREUM NFT PROJECT
		</div>
	</div>
	</div>
	<div className="main w-100 text-center" style={{margin: 'auto'}}>
	{/* <div className="vw-100 vh-100 p-4" /> */}
	<div className="bg-tan vw-100" style={{}}>
		<div className="d-none d-md-block">
			<Header />
		</div>
		<div className="pt-0 pb-3">
			<div className="container" style={{maxWidth: 1050}}>
				<div className="row">
					<div className="col-md-6 my-4">
						<div id="mainImage"></div>
					</div>
					<div className="col-md-6 rubikFont bg-tan py-4" id="mainText">
						{ mintFlow() }
						<p className="mt-6 small hex-gray text-uppercase fw-800 d-block d-md-none"><a className="a" target={"_blank"} href={CONFIG.SCAN_LINK}>{truncate(CONFIG.CONTRACT_ADDRESS, 20)}</a></p>

					</div>
					<a className="anone d-block d-md-none" href="https://www.veovinci.com/" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
							<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
						</svg>
					</a>
				</div>
			</div>
		</div>
	</div>

	<div className="vw-100 bg-tan hex-1e">
		<div style={{height: 150}} />
		<div className="banner" style={{transform: 'rotate(0deg)', marginLeft: -10, marginRight: -10}}>
			<div className="track rubikFont" style={{top: '-1vw'}}>
				<div className="bg-red" style={{position: 'relative', top: '55%', height: '0.5vw'}}></div>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
				<span className="textMoveR" style={{marginLeft: '9vw'}}>SHOES</span>
			</div>
		</div>
		<div className="banner" style={{transform: 'rotate(-5deg)', marginLeft: -10, marginRight: -10}}>
			<div className="track rubikFont" style={{top: '-1vw', backgroundColor: '#DE443D', color: '#EDEDE4'}}>
				<div className="bg-1e" style={{position: 'relative', top: '55%', height: '0.5vw'}}></div>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
				<span className="textMove" style={{marginLeft: '9vw'}}>FUSE</span>
			</div>
		</div>
		<div style={{height: '5vw'}} />
		<div className="banner" style={{transform: 'rotate(3deg)', marginLeft: -10, marginRight: -10}}>
		<div className="track rubikFont" style={{top: '-1vw', backgroundColor: '#1e1e1e', color: '#EDEDE4'}}>
		<div className="bg-red" style={{position: 'relative', top: '55%', height: '0.5vw'}}></div>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
				<span className="textMoveR3" style={{marginLeft: '9vw'}}>NFT</span>
			</div>
		</div>
		<div style={{height: 200}}></div>
		<div className="container">
			<h1 className="rubikFont text-uppercase fw-800 hex-1e" style={{fontSize: 60}}>Welcome to the <span className="hex-red">Shoes</span> <span className="hex-gray">Fuse</span> lab</h1>
			<h4 className="py-2 rubikFont hex-1e m-0">We fuse shoe tops &amp; soles to create new fusion designs</h4>
		</div>
		<div className="d-flex centerEverything m-4">
			<div className="image be-1e" style={{backgroundImage: 'url("images/swap.gif")', width: '95%', paddingTop: '19%'}} />
		</div>
		<div className="row markerFont" style={{width: '90%', margin: 'auto'}}>
			<div className="col"><h4>Yeezys</h4></div>
			<div className="col"><h4>Jordan 1s</h4></div>
			<div className="col"><h4>Red Octobers</h4></div>
		</div>
	</div>

	<div className="bg-tan">
		<div style={{height: 100}}></div>
		<div className="container">
			<div className="row rows-cols-1 row-cols-md-2 px-2">
				<a className="a" href="/#/collection">
					<div className="col">
						<div className="bg-f round my-4 p-4">
							<div className="p-4">
								<h1 className="rubikFont fw-800 text-uppercase">View NFT Collection</h1>
							</div>
						</div>
					</div>
				</a>
				<a className="a" target={"_blank"} href="https://nft.shoesfuse.com">
					<div className="col">
						<div className="bg-f round my-4 p-4">
							<div className="p-4">
								<h1 className="rubikFont fw-800 text-uppercase">View Members Club</h1>
							</div>
						</div>
					</div>
				</a>
			</div>
			<div className="row row-cols-1 px-2">
				<div className="col">
					<div className="bg-f hex-1e round my-4 p-4">
						<div className="p-4">
							<h1 className="rubikFont fw-800 text-uppercase">NIKELAR FUSION <br /> of TWO SHOES (NFTS)</h1>
							<p style={{color: '#555'}}>
							In our lab, we take shoe uppers and soles from shoes fuse them together to
							create new designs. We call them "NFTs". We created a limited edition collection of <span className="fw-bold">5000</span> NFTs.
							These are some of the shoes we are fusing in our lab.
								How many you can identify?
							</p>
						</div>
						<div className="image m-auto" style={{backgroundImage: 'url("images/artboard.png")', width: '100%', paddingTop: '100%'}} />
					</div>
				</div>
			</div>
			<div className="row row-cols-1 px-2">
				<div className="col">
					<div className="bg-1e hex-e7 round my-4 p-4">
						<div className="image mh-400p mt-4" style={{backgroundImage: 'url("images/pic2.png")'}} />
						<div className="d-flex centerEverything">
						<div className="p-4 rubikFont">
							<h1 className="text-uppercase hex-f">NFT OWNERSHIP PERKS</h1>
							<p style={{color: '#555'}}>
							As a SHOES FUSE NFT owner you get some exclusive rights!
							</p>
							<div className="d-flex flex-wrap justify-content-between mb-4">
								<div className="p-4 m-3 text-center round w-100" style={{outline: '1px solid #555',}}>
									<h5 className="fw-bold text-uppercase">A S3xy 4K High-Res Image of your NFT</h5>
									<div className="card-text" style={{color: '#ccc'}}>You can show off your new kicks in style by setting your NFT image as your profile picture!</div>
								</div>
								<div className="p-4 m-3 text-center round w-100" style={{outline: '1px solid #555',}}>
									<h5 className="fw-bold text-uppercase">
										<a className="a" target={"_blank"} href="https://nft.shoesfuse.com">
											Access to the Members Only site
										</a>
									</h5>
									<div className="card-text" style={{color: '#ccc'}}>The Members Only Site contains the Shoe ART WALL - a canvas accessible only to wallets containing at least one Shoes Fuse NFT. It's the place to collaborate and make pixel art!. The members only site will also be where future merch will be available to only Shoes Fuse NFT owners.</div>
								</div>
								<div className="p-4 m-3 text-center round w-100" style={{outline: '1px solid #555',}}>
									<h5 className="fw-bold text-uppercase">Eligibilty to own 5 SUPER <span className="badge bg-danger">Rare</span> NFTs</h5>
									<div className="card-text" style={{color: '#ccc'}}>Hidden in the collection are 5 super rare NFTs. Try your luck to find them!</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row row-cols-1 row-cols-lg-2 px-2">
				<div className="col">
					<div className="bg-1e hex-e7 round my-4 p-4">
						<div className="d-flex centerEverything">
							<div className="p-4">
								<h1 className="rubikFont text-uppercase hex-f">How to Mint a NFT</h1>
								<div className="text-start rubikFont p-1 hex-f">
									<h5 className="mt-4"><a className="a" href="https://www.metamask.io" target="_blank">1. Get METAMASK</a></h5>
									<p style={{color: '#999'}}>In order to "buy" or mint an NFT, you must have Metamask extension installed on your web browser.</p>
									<h5 className="mt-4">2. Load ETH into your wallet.</h5>
									<p style={{color: '#999'}}>You will need to load your Ethereum Wallet with ETH tokens through services that allow you to exchange your money to ETH like Coinbase.</p>
									<h5 className="mt-4">3. ADD TO CART and MINT!</h5>
									<p style={{color: '#999'}}>Scroll up, click "ADD TO CART", verify this app on Metamask, and then click "MINT"!</p>
									<h5 className="mt-4"><a className="a" target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>4. View on OPENSEA</a></h5>
									<p style={{color: '#999'}}>Your NFT will be immediately available for you to view! Check it out on Opensea and in the <a className="a" href="/#/collection">collection view</a>.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col">
					<div className="bg-1e hex-e7 round my-4 p-4 pt-0">
						<div className="image mh-400p my-4" style={{backgroundImage: 'url("images/pic1.png")'}} />
						<div className="d-flex centerEverything">
						<div className="p-4 rubikFont">
							<h1 className="text-uppercase hex-f">Just for Kicks</h1>
							<p style={{color: '#ccc'}}>
							This project was made for fun and so there is currently no roadmap or Discord channel.
							Any updates to the project will be made on Instagram and Twitter. Be sure to be following!
							A lot of late nights went into hand drawing every shoe and other art.
							If you'd like to support the project creator and artist
							<a href="https://www.instagram.com/veovinci" target="_blank" className="a" data-size="large" data-show-count="false"><span className="fw-bold"> VeoVinci</span></a>,
							please consider minting an NFT. 
							</p>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row row-cols-1 px-2">
				<div className="col">
					<div className="hex-1e round my-4 p-4 round" style={{outline: '3px solid #fff'}}>
						<div className="d-flex centerEverything">
							<div className="p-4 rubikFont">
								<div type="button" data-bs-toggle="collapse" data-bs-target="#flush-tac">
									<h6 className="small fw-400 text-uppercase">Shoes Fuse 2022. All Rights Reserved.</h6>
									<h4 className="text-uppercase">Terms and Conditions</h4>
									{/* <span className="small">Shoes Fuse & VeoVinci are NOT affiliated with any brands represented.</span> */}
									<p className="small text-uppercase mb-0">Click to View</p>
								</div>
								<div id="flush-tac" className="collapse p-4" style={{textAlign: 'justify'}}>
									TERMS & CONDITIONS

									Shoes Fuse is a collection of digital artworks (NFTs) running on the Ethereum network.
									This website is only an interface allowing participants to exchange digital collectibles.
									Users are entirely responsible for the safety and management of their own private ETH wallets
									and validating all transactions and contracts generated by this website before approval.
									As the Shoes Fuse smart contract runs on the Ethereum network, there is no ability to reverse,
									undo or restore any transactions. This website and its connected services are provided “as is”
									and “as available” without a warranty of any kind. By using this website you are accepting sole
									responsibility for any and all transactions involving Shoes Fuse digital collectibles.

									OWNERSHIP
									i. You Own the NFT. Each Shoes Fuse is an NFT on the Ethereum blockchain. When you purchase an NFT,
									you own the underlying Shoes Fuse, the Art, completely. Ownership of the NFT is mediated entirely
									by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify
									the ownership of any Shoes Fuse.

									ii. Personal Use. Subject to your continued compliance with these Terms, Shoes Fuse grants you a worldwide,
									royalty-free license to use, copy, and display the purchased Art, along with any extensions that you
									choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use;

									(ii) as part of a marketplace that permits the purchase and sale of your Shoes Fuse / NFT, provided
									that the marketplace cryptographically verifies each Shoes Fuse owner’s rights to display the Art for
									their Shoes Fuse to ensure that only the actual owner can display the Art; or (iii) as part of a third
									party website or application that permits the inclusion, involvement, or participation of your Shoes Fuse,
									provided that the website/application cryptographically verifies each Shoes Fuse owner’s rights to display
									the Art for their Shoes Fuse to ensure that only the actual owner can display the Art, and provided that
									the Art is no longer visible once the owner of the Shoes Fuse leaves the website/application.

									iii. Commercial Use. Subject to your continued compliance with these Terms, Shoes Fuse grants you
									an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating
									derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be
									the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art.
									For the sake of clarity, nothing in this Section will be deemed to prevent you from (i) owning or operating
									a marketplace that permits the use and sale of Shoes Fuse generally, provided that the marketplace
									cryptographically verifies each Shoes Fuse owner’s rights to display the Art for their Shoes Fuse to
									ensure that only the actual owner can display the Art; (ii) owning or operating a third party website
									or application that permits the inclusion, involvement, or participation of Shoes Fuse generally, provided
									that the third party website or application cryptographically verifies each Shoes Fuse owner’s rights to
									display the Art for their Shoes Fuse to ensure that only the actual owner can display the Art, and provided
									that the Art is no longer visible once the owner of the Purchased Shoes Fuse leaves the application/website;
									or (iii) earning revenue from any of the foregoing.

									<br /><br />
									<span className="fw-bold">
									Shoes Fuse & VeoVinci are NOT affiliated with any brands that may be represented in this project or in the NFT images or metadata.
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style={{height: 50}}></div>
	</div>

	<div className="vw-100 vh-100 p-4" />

	<a className="anone" href="https://www.veovinci.com/" target="_blank">
        <footer className="footer mt-auto">
            <div className="image m-4" style={{height: 50, backgroundImage: "url('https://www.veovinci.com/images/icon.png')"}} />
        </footer>
    </a>

</div>

	</>);
}

export default Home;
